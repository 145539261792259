import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { Image } from 'react-bootstrap'
import { FaFileAlt, FaFileExcel, FaFilePdf, FaFileWord } from 'react-icons/fa'
import { IoCheckmarkDoneOutline } from 'react-icons/io5'
import styles from './styles.module.css'

interface UserObject {
  name: string
  email: string
  number: string
  typing: boolean
  id: string
  role: string
  image: string
}
interface ChatObject {
  senderId?: string
  message: string
  time?: number
  status?: 'SEND' | 'RECEIVED' | 'SEEN'
  id?: string
  users?: UserObject[]
  sender?: string
  files?: File[]
  soundUrl?: string
}
function Index({
  senderId,
  message,
  time,
  users,
  status,
  sender,
  files,
  soundUrl
}: ChatObject) {
  const user = users?.find((user) => user.id === senderId)
  const hisOwnMsg = user?.id === sender
  const audioRef = useRef<any>(null)

  useEffect(() => {
    if (status !== 'SEEN' && !hisOwnMsg && time) {
      audioRef.current.play()
      return
    }
    audioRef.current.pause()
  }, [status, hisOwnMsg, time])
  return (
    <div
      className={`${styles.chatBox} ${
        hisOwnMsg ? styles.msgRight : styles.msgLeft
      }`}
    >
      <audio ref={audioRef} src={soundUrl} />
      <div className={styles.msg}>
        {!hisOwnMsg && (
          <Image
            className={styles.chatImg}
            src={
              user?.image ||
              'https://www.clipartmax.com/png/middle/349-3496330_download-person-icon-orange-clipart-computer-icons-user-icon-orange-png.png'
            }
          />
        )}
        <div className={styles.chatText}>
          {message && <p>{message} </p>}
          {files?.map((file: any, index) => {
            const type = file?.type || file?.file?.type
            const finalType = type.split('/')[0]

            return (
              <a
                href={file.url}
                style={{ marginBlock: '20px' }}
                key={index}
                target='_blank'
                rel='noreferrer'
              >
                {finalType === 'image' && (
                  <img
                    src={file.url}
                    alt='file'
                    style={{
                      maxHeight: '300px',
                      maxWidth: '300px',
                      borderRadius: '10px'
                    }}
                  />
                )}
                {type === 'application/pdf' && (
                  <FaFilePdf
                    color='red'
                    style={{
                      maxHeight: '200px',

                      maxWidth: '200px',
                      height: '200px',

                      width: '200px'
                    }}
                  />
                )}
                {type === 'text/plain' && (
                  <FaFileAlt
                    color='black'
                    style={{
                      maxHeight: '200px',

                      maxWidth: '200px',
                      height: '200px',

                      width: '200px'
                    }}
                  />
                )}
                {(type ===
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  type === 'application/msword') && (
                  <FaFileWord
                    color='blue'
                    style={{
                      maxHeight: '200px',

                      maxWidth: '200px',
                      height: '200px',

                      width: '200px'
                    }}
                  />
                )}
                {type ===
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
                  <FaFileExcel
                    color='green'
                    style={{
                      maxHeight: '200px',

                      maxWidth: '200px',
                      height: '200px',

                      width: '200px'
                    }}
                  />
                )}
              </a>
            )
          })}
          {time && (
            <div
              style={{ display: 'flex', alignItems: 'center', color: 'black' }}
            >
              <small
                style={{
                  color: 'black'
                }}
              >
                {moment(time).format('h:mm a')}
              </small>

              {hisOwnMsg && (
                <IoCheckmarkDoneOutline
                  color={status === 'SEEN' ? '#f8b615' : '#282932'}
                />
              )}
            </div>
          )}
        </div>

        {hisOwnMsg && (
          <Image
            className={styles.chatImg}
            src={
              user?.image ||
              'https://banner2.cleanpng.com/20180411/zrq/kisspng-computer-icons-user-symbol-encapsulated-postscript-info-5acde5207d4049.1889199815234429765131.jpg'
            }
          />
        )}
      </div>
    </div>
  )
}

export default Index
