/* eslint-disable no-unused-expressions */
import moment from 'moment'
import * as React from 'react'
import {
  FaLocationArrow,
  FaPaperclip,
  FaFilePdf,
  FaFileAlt,
  FaFileWord,
  FaFileExcel,
  FaSpinner
} from 'react-icons/fa'
import Message from './Message'
import styles from './styles.module.css'
import { v4 as uuidv4 } from 'uuid'
interface UserObject {
  name: string
  email: string
  number: string
  typing: boolean
  id: string
  role: string
  image: string
}
interface ChatObject {
  senderId?: string
  message: string
  time: number
  status: 'SEND' | 'RECEIVED' | 'SEEN'
  id: string
  files?: File[]
}
interface session {
  createdAt: number
  startAt?: number
  endAt?: number
  endBy?: string
  startBy?: string
  createdBy: string
  id: string
  platform?: 'client' | 'rider' | 'restaurant'
}
interface typingObject {
  chat?: ChatObject
  session?: session
  typing?: boolean
}
interface Props {
  chats?: ChatObject[]
  users?: UserObject[]
  session?: session
  sender: string
  onTyping: (typing: boolean) => void
  onSend: (sentObject: typingObject) => void
  title: string
  wellcomeMessage?: string
  containerStyle?: React.CSSProperties
  soundUrl?: string
}

const Chat = ({
  chats,
  users,
  session,
  onTyping,
  onSend,
  containerStyle,
  sender,
  title,
  wellcomeMessage,
  soundUrl
}: Props) => {
  const [message, setMassage] = React.useState('')
  const [files, setFiles] = React.useState<Blob[] | any>([])

  const msgConatinerRef = React.useRef<HTMLDivElement | any>()
  const onTypingHandle = (e: { target: HTMLInputElement }) => {
    setMassage(e.target.value)
    onTyping(true)
  }
  const onSendHandle = () => {
    if (message.length <= 0 && files.length <= 0) {
      return
    }
    if (session?.endAt) {
      return
    }
    if (!session) {
      onSend({
        chat: {
          message,
          time: Number(moment().format('x')),
          status: 'SEND',
          id: uuidv4(),
          senderId: sender,
          files
        },
        session: {
          createdAt: Number(moment().format('x')),

          createdBy: sender,
          id: uuidv4()
        },
        typing: false
      })
      setMassage('')
      setFiles([])
      return
    }
    onSend({
      chat: {
        message,
        time: Number(moment().format('x')),
        status: 'SEND',
        id: uuidv4(),
        senderId: sender,
        files
      },
      typing: false
    })

    setMassage('')
    setFiles([])
  }

  const onKeyDownHandle = (e: any) => {
    if (message.length <= 0 && files.length <= 0) {
      return
    }
    if (session?.endAt) {
      return
    }
    if (e.key === 'Enter') {
      if (!session) {
        onSend({
          chat: {
            message,
            time: Number(moment().format('x')),
            status: 'SEND',
            id: uuidv4(),
            senderId: sender,
            files
          },
          session: {
            createdAt: Number(moment().format('x')),

            createdBy: sender,
            id: uuidv4()
          },
          typing: false
        })
        setMassage('')
        setFiles([])

        return
      }
      onSend({
        chat: {
          message,
          time: Number(moment().format('x')),
          status: 'SEND',
          id: uuidv4(),
          senderId: sender,
          files
        },
        typing: false
      })

      setMassage('')
      setFiles([])
    }
  }

  const onFilehandle = (e: { target: HTMLInputElement | any }) => {
    const files = e.target.files
    const updatedFiles = Array.from(files).map((e: any) => {
      return {
        file: e,
        url: URL.createObjectURL(e)
      }
    })
    setFiles(updatedFiles)
  }
  React.useEffect(() => {
    msgConatinerRef?.current?.scrollIntoView({
      block: 'nearest',
      inline: 'center',
      behavior: 'smooth',
      alignToTop: false
    })
  }, [chats])

  const reciverUser = users?.find((e) => e.id !== sender)

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.chatBoxMain}>
        {title && <h3 className={styles.title}>{title}</h3>}

        <div ref={msgConatinerRef} className={styles.chatMsgs}>
          {wellcomeMessage && (
            <Message message={wellcomeMessage} users={users} sender='ChatBot' />
          )}
          {chats?.map((msg, index) => {
            return (
              <React.Fragment key={index}>
                <Message
                  soundUrl={soundUrl}
                  {...msg}
                  users={users}
                  sender={sender}
                />
                <div ref={msgConatinerRef} />
              </React.Fragment>
            )
          })}

          {reciverUser?.typing && (
            <React.Fragment>
              <Message
                message='typing...'
                users={users}
                senderId={users?.find((e) => e.id !== sender)?.id}
              />
              <div ref={msgConatinerRef} />
            </React.Fragment>
          )}
        </div>

        <div className={styles.chatFooter}>
          {session?.endBy ? (
            <div style={{ width: '100%' }}>
              <p
                style={{
                  margin: 0,
                  fontSize: '12px',
                  fontWeight: 700,
                  textAlign: 'center',
                  color: 'black'
                }}
              >
                {users?.find((e) => e.id === session?.startBy)?.name} has ended
                this session ({moment(Number(session?.endAt)).format('h:mm a')})
              </p>
            </div>
          ) : (
            <React.Fragment>
              {session?.createdAt && sender === session.createdBy && (
                <div style={{ width: '100%' }}>
                  <p
                    style={{
                      margin: 0,
                      fontSize: '12px',
                      fontWeight: 700,
                      textAlign: 'center',
                      color: 'black'
                    }}
                  >
                    {!session?.startBy ? (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingRight: 10,
                          width: '100%'
                        }}
                      >
                        <FaSpinner
                          style={{
                            margin: 3
                          }}
                        />
                        We are currently helping a lot of customers at the
                        moment, we will be with you as soon as we can.
                      </span>
                    ) : (
                      <span>
                        {users?.find((e) => e.id === session?.startBy)?.name} is
                        connected
                      </span>
                    )}
                  </p>
                </div>
              )}
            </React.Fragment>
          )}
          {!session?.endAt && (
            <div
              className={styles.senderBox}
              style={
                files.length > 0
                  ? {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderTop: '1px solid white'
                    }
                  : {}
              }
            >
              <label htmlFor='file' className={styles.sendIconRigth}>
                <input
                  id='file'
                  type='file'
                  multiple
                  hidden
                  onChange={onFilehandle}
                />
                <FaPaperclip style={{ cursor: 'pointer' }} color='white' />
              </label>
              <input
                value={message}
                type='text'
                onKeyDown={onKeyDownHandle}
                onChange={onTypingHandle}
              />
              <div className={styles.sendIcon}>
                <FaLocationArrow
                  style={{ cursor: 'pointer' }}
                  onClick={onSendHandle}
                  color='white'
                />
              </div>
              {files.length > 0 && (
                <div className={styles.files}>
                  {files.map((file: any, index: number) => {
                    return (
                      <div key={index} className={styles.file}>
                        {(file.file.type === 'image/jpeg' ||
                          file.file.type === 'image/png' ||
                          file.file.type === 'image/svg+xml') && (
                          <img src={file.url} alt='file' />
                        )}
                        {file.file.type === 'application/pdf' && (
                          <FaFilePdf
                            color='red'
                            style={{
                              height: '100%',
                              width: '100%'
                            }}
                          />
                        )}
                        {file.file.type === 'text/plain' && (
                          <FaFileAlt
                            color='white'
                            style={{
                              height: '100%',
                              width: '100%'
                            }}
                          />
                        )}
                        {(file.file.type ===
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                          file.file.type === 'application/msword') && (
                          <FaFileWord
                            color='white'
                            style={{
                              height: '100%',
                              width: '100%'
                            }}
                          />
                        )}
                        {file.file.type ===
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
                          <FaFileExcel
                            color='white'
                            style={{
                              height: '100%',
                              width: '100%'
                            }}
                          />
                        )}
                        <h1>
                          {file.file.name.substring(0, 3)}***.
                          {
                            file.file.name.split('.')[
                              file.file.name.split('.').length - 1
                            ]
                          }
                        </h1>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default Chat
